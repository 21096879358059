<template>
  <div>
    <el-form :model="form_data" :rules="formRules" :ref="form_name"  size="mini"
             label-position="right" label-width="100px">
      <div style="display: flex;align-items: center;justify-content:space-between;height: 60px;margin-bottom: 30px;">
        <div style="display: flex;align-items: center;margin-left: 48px;flex: 1">
          <el-avatar :src="form_data.avatar"></el-avatar>
          <div style="margin-left: 12px;font-size: 16px;font-weight: bold">{{ form_data.nick_name }}</div>
        </div>
        <div style="flex: 1;display: flex;justify-content: center">
          <div style="text-align:center;width: 48px;margin-left: 12px;font-size: 12px;font-weight: bold;padding: 12px 24px;background: #F56C6C;color: #f0f2f5;border-radius: 120px">
            上课中
          </div>
        </div>
        <div style="display: flex;align-items: flex-end;justify-content: center;flex-direction: column;flex: 1">
          <div style="font-size: 16px;font-weight: bold;margin-bottom:12px;color: #409EFF">会员编号：{{form_data.user_no}}</div>
          <div>渠道单号：{{  }}</div>
        </div>
      </div>
      <div style="display: flex;align-items: center;justify-content:space-between;height: 60px;margin-bottom: 60px">
        <div style="display: flex;margin-left: 48px;flex-direction: column;flex: 1">
          <div style="margin-bottom: 12px;">创建时间：{{ elTimeStampFormat(form_data.created_at)}}</div>
          <div>成交时间：{{ elTimeStampFormat(form_data.created_at)}}</div>
        </div>
        <div style="flex: 1">
          <div style="flex: 1;text-align: end;color: #888;margin-bottom: 12px;">应付：25.00</div>
          <div style="flex: 1;text-align: end;font-size: 16px;color: #F56C6C;font-weight: bold">实付：25.00</div>
        </div>
      </div>
      <el-form-item label="备注1:" prop="remark_1">
        <el-input v-model="form_data.remark_1"></el-input>
      </el-form-item>
      <el-form-item label="备注2:" prop="remark_2">
        <el-input v-model="form_data.remark_2"></el-input>
      </el-form-item>
      <el-form-item label="备注3:" prop="remark_3">
        <el-input v-model="form_data.remark_3"></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align:right;">
      <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
      <el-button @click="onCloseDialog" size="mini">关闭</el-button>
    </div>
  </div>
</template>

<script>

import {getParams} from "@/api/vstar/param";
import {getDetail, updateInfo} from "@/api/vstar/order";
import {formatTimestamp} from "@/utils/time";
const param_list = ['order_status']

export default {
  name: "order-detail",
  props: {
    rowId: {
      value: String,
    }
  },
  data() {
    return {
      imgHost: process.env.VUE_APP_BASE_API,
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/sys/file/upload',
      uploadHeader: {},

      form_name: 'playground_detail',
      form_data: {},
      formRules: {},
      params: {},
      params_map: {},
      params_map_reverse: {},
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    initPage() {
      this.initParams().then(() => {
        if (this.rowId > 0) {
          getDetail(this.rowId).then(resp => {
            this.form_data = resp.data
            console.log('this.form_data',this.form_data)
          })
        }
      })
    },
    initParams(){
      return new Promise((resolve,reject)=>{
        getParams(param_list.join(','),'en').then(resp=>{
          this.params = resp.data;

          let tmp_params_map = {}
          let tmp_params_map_pure = {}
          let tmp_params_map_reverse = {}

          //这里再这里一下每组参数做成kv方便显示
          let p_keys = Object.keys(this.params)
          p_keys.forEach(pl=>{
            console.log('pl:',pl)
            let tmp_map = {}
            let tmp_map_pure = {}
            let tmp_map_reverse = {}
            this.params[pl].forEach(pm=>{
              // tmp_map[pm.key]=pm.value
              tmp_map_pure[pm.key]=pm.value
              tmp_map[pm.key]={key:pm.key,value:pm.value,color:pm.color}

              //再调转处理一下 导入Excel参数解析用
              tmp_map_reverse[pm.value]=pm.key
            })
            tmp_params_map[pl] = tmp_map
            tmp_params_map_pure[pl] = tmp_map_pure
            tmp_params_map_reverse[pl] = tmp_map_reverse
          })

          this.params_map = tmp_params_map
          this.params_map_pure = tmp_params_map_pure
          this.params_map_reverse = tmp_params_map_reverse
          // console.log('params_map:',this.params_map)
          // console.log('params_map_reverse:',this.params_map_reverse)
          resolve(resp)
        }).catch((err)=>{
          if(err){
            console.error(err)
          }
          reject(err)
        })
      })
    },
    elTimeStampFormat(val) {
      return formatTimestamp(val)
    },
    onCloseDialog() {
      this.$emit("onTellDialogClose")
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = Object.assign({},this.form_data)
          updateInfo(fd).then(() => {
            this.$emit('onTellDialogClose')
          })
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
