import request from '@/utils/request'

export const getList = (page,size,params)=>{
    return request({
        url:'/api/vstar/order/list',
        method:'get',
        params:{...params,page,size}
    });
}

export const getDetail = (id)=>{
    return request({
        url:'/api/vstar/order/detail',
        method:'get',
        params:{id}
    })
}


export const updateInfo = (data)=>{
    return request({
        url:'/api/vstar/order/update-info',
        method:'post',
        data:data
    })
}

export const exportExcel = (params)=>{
    return request({
        url:'/api/vstar/order/export-excel',
        method:'get',
        params:{...params,t:Math.random()},
        responseType:'blob'
    })
}
