<template>
  <div class="rb-view">
    <div>
      <div>
        <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
          <el-row>
            <el-col :span="24">
              <el-form-item label="订单日期:" prop="time_range">
                <div style="display: flex;align-items: center">
                  <el-radio-group v-model="search_option.time_range" @change="handleSearchTap">
                    <el-radio-button label="all">全部</el-radio-button>
                    <el-radio-button label="today">今日</el-radio-button>
                    <el-radio-button label="yesterday">昨天</el-radio-button>
                    <el-radio-button label="7day">7天内</el-radio-button>
                    <el-radio-button label="30day">30天内</el-radio-button>
                    <el-radio-button label="this_month">今月</el-radio-button>
                    <el-radio-button label="this_year">今年</el-radio-button>
                  </el-radio-group>
                  <span>
                    <span style="margin-left:20px;margin-right:10px;">自定义:</span>
                      <el-date-picker @change="handleSearchTap"
                                      v-model="search_option.time_range"
                                      type="daterange"
                                      range-separator="至"
                                      start-placeholder="开始日期"
                                      end-placeholder="结束日期">
                      </el-date-picker>
                  </span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单状态:" prop="order_status">
                <el-radio-group v-model="search_option.order_status" @change="handleSearchTap">
                  <el-radio-button label="all">全部</el-radio-button>
                  <el-radio-button label="un_pay">未支付</el-radio-button>
                  <el-radio-button label="complete">已完成</el-radio-button>
                  <el-radio-button label="cancel">已取消</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="支付状态:" prop="pay_status">
                <el-radio-group v-model="search_option.pay_status" @change="handleSearchTap">
                  <el-radio-button label="all">全部</el-radio-button>
                  <el-radio-button label="un_pay">未支付</el-radio-button>
                  <el-radio-button label="paid">已支付</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="订单号:" prop="id">
                <el-input clearable v-model="search_option.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="会员ID:" prop="user_no">
                <el-input clearable v-model="search_option.user_no"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="昵称:" prop="nick_name">
                <el-input clearable v-model="search_option.nick_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="渠道单号:"  prop="transaction_id">
                <el-input clearable v-model="search_option.transaction_id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="备注:" prop="remark">
                <el-input clearable v-model="search_option.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询
              </el-button>
              <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
            </el-col>
            <el-col :span="12" align="right">
              <el-button type="primary" icon="el-icon-tickets" size="mini" @click="onExportTap('page')">导出当页</el-button>
              <el-button type="primary" icon="el-icon-tickets" size="mini" @click="onExportTap('all')">导出全部</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="margin-top: 16px;">
        <el-table size="small" v-loading="loading" :data="page_list" border @sort-change="handleSortChange">
          <el-table-column label="创建时间" prop="created_at" width="136" :formatter="elTimeStampFormat" sortable="custom"></el-table-column>
          <el-table-column label="ID" prop="id" width="150" sortable="custom"></el-table-column>
          <el-table-column label="排课ID" prop="schedule_id" width="150"></el-table-column>
          <el-table-column label="排课ID" prop="fitness_id" width="150"></el-table-column>
          <el-table-column label="订单状态" prop="status" sortable="custom" width="120">
            <template #default="scope">
              <span
                  :style="{color:(params_map['order_status']&&params_map['order_status'][scope.row.status])?params_map['order_status'][scope.row.status].color:''}">{{
                  (params_map['order_status'] && params_map['order_status'][scope.row.status]) ? params_map['order_status'][scope.row.status].value : ''
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户ID" prop="user_id" width="150" sortable="custom"></el-table-column>
          <el-table-column label="OpenID" prop="open_id" width="150"></el-table-column>
          <el-table-column label="会员ID" prop="user_no" width="136" sortable="custom"></el-table-column>
          <el-table-column label="头像" prop="avatar" width="49">
            <template #default="scope">
              <el-avatar :src="scope.row.avatar" size="small"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="昵称" prop="nick_name"></el-table-column>
          <el-table-column label="订单名称" prop="title"></el-table-column>
          <el-table-column label="开课时间" prop="schedule_begin_at" width="136" :formatter="elTimeStampFormat" sortable="custom"></el-table-column>
          <el-table-column label="座位" prop="seat" sortable="custom"></el-table-column>
          <el-table-column label="原价" prop="market_price" align="right" sortable="custom"></el-table-column>
          <el-table-column label="价格" prop="price" align="right" sortable="custom"></el-table-column>
          <el-table-column label="会员折扣" prop="user_discount" align="right" sortable="custom" width="120"></el-table-column>
          <el-table-column label="应收" prop="amount" align="right" sortable="custom">
            <template #default="scope">
              <span style="color: #F56C6C;font-weight: bold">{{ scope.row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实付" prop="payment" align="right" sortable="custom">
            <template #default="scope">
              <span style="color: #F56C6C;font-weight: bold">{{ scope.row.payment }}</span>
            </template>
          </el-table-column>
          <el-table-column label="支付渠道" prop="cash_payment" align="center"></el-table-column>
          <el-table-column label="其他支付渠道" prop="other_payment" width="136"
                           :formatter="elTimeStampFormat"></el-table-column>
          <el-table-column label="开始支付时间" prop="start_pay_at" width="136"
                           :formatter="elTimeStampFormat" sortable="custom"></el-table-column>
          <el-table-column label="支付完成时间" prop="pay_done_at" width="136"
                           :formatter="elTimeStampFormat" sortable="custom"></el-table-column>
          <el-table-column label="支付状态" prop="pay_status" sortable="custom" width="120">
            <template #default="scope">
              <span
                  :style="{color:(params_map['pay_status']&&params_map['pay_status'][scope.row.status])?params_map['pay_status'][scope.row.status].color:''}">{{
                  (params_map['pay_status'] && params_map['pay_status'][scope.row.status]) ? params_map['pay_status'][scope.row.status].value : ''
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="微信支付订单号" prop="transaction_id" width="240"></el-table-column>
          <el-table-column label="退款完成时间" prop="refund_at" width="136" :formatter="elTimeStampFormat" sortable="custom"></el-table-column>
          <el-table-column label="退款状态" prop="refund_status" sortable="custom" width="120">
            <template #default="scope">
              <span
                  :style="{color:(params_map['refund_status']&&params_map['refund_status'][scope.row.status])?params_map['refund_status'][scope.row.status].color:''}">{{
                  (params_map['refund_status'] && params_map['refund_status'][scope.row.status]) ? params_map['refund_status'][scope.row.status].value : ''
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款金额" prop="refund_amount" sortable="custom" width="120"></el-table-column>
          <el-table-column label="退款单号" prop="out_refund_no" width="240"></el-table-column>
          <el-table-column label="成交时间" prop="pay_done_at" width="136" :formatter="elTimeStampFormat" sortable="custom"></el-table-column>
          <el-table-column label="渠道单号" prop="transaction_id" width="240" show-overflow-tooltip></el-table-column>
          <el-table-column label="备注1" prop="remark_1" width="136" show-overflow-tooltip></el-table-column>
          <el-table-column label="备注2" prop="remark_2" width="136" show-overflow-tooltip></el-table-column>
          <el-table-column label="备注3" prop="remark_3" width="136" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template #default="scope">
              <el-button type="text" @click="onRowViewTap(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page_option.page"
              :page-sizes="[5,10, 20, 30, 50,100,200]"
              :page-size="page_option.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page_option.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog v-model="dialog_order_detail_visible" :title="dialog_order_detail_title" @close="onTellDialogClose"
               width="800px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
      <order-detail :row-id="dialog_order_detail_id" mode="single" scene="send"
                    @onTellDialogClose="onTellDialogClose"></order-detail>
    </el-dialog>
  </div>
</template>

<script>
import {getList} from "@/api/vstar/order";
import {getParams} from "@/api/vstar/param";
import {formatTimestamp, formatTimestampDay} from "@/utils/time";
import OrderDetail from "@/views/b/order/order-detail";
import {exportExcel} from "../../../api/vstar/order";

const param_list = ['pay_status', 'refund_status', 'order_status']
export default {
  name: "order-list",
  components: {OrderDetail},
  data() {
    return {
      loading: false,
      search_form_name: 'order_search',
      page_option: {page: 1, size: 10, total: 0},
      search_option: {id: '', user_no: '', nick_name: '', order_status: 'all', pay_status:'all',time_range: 'all', transaction_id: ''},
      page_list: [],
      params: {order_play_status: [], play_type: []},
      params_map_pure: {},
      params_map: {order_play_status: {}, play_type: {}},


      dialog_order_detail_visible: false,
      dialog_order_detail_title: '',
      dialog_order_detail_id: '',
    }
  },
  created() {
    this.initPage()
  },
  computed: {
    formDisabled() {
      return this.action === 'view' || !this.action;
    }
  },
  methods: {
    initPage() {
      this.initParams().then(() => {
        this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
      })
    },
    initParams() {
      return new Promise((resolve, reject) => {
        getParams(param_list.join(','), 'en').then(resp => {
          this.params = resp.data;

          let tmp_params_map = {}
          let tmp_params_map_pure = {}
          let tmp_params_map_reverse = {}

          //这里再这里一下每组参数做成kv方便显示
          let p_keys = Object.keys(this.params)
          p_keys.forEach(pl => {
            console.log('pl:', pl)
            let tmp_map = {}
            let tmp_map_pure = {}
            let tmp_map_reverse = {}
            this.params[pl].forEach(pm => {
              // tmp_map[pm.key]=pm.value
              tmp_map_pure[pm.key] = pm.value
              tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

              //再调转处理一下 导入Excel参数解析用
              tmp_map_reverse[pm.value] = pm.key
            })
            tmp_params_map[pl] = tmp_map
            tmp_params_map_pure[pl] = tmp_map_pure
            tmp_params_map_reverse[pl] = tmp_map_reverse
          })

          this.params_map = tmp_params_map
          this.params_map_pure = tmp_params_map_pure
          this.params_map_reverse = tmp_params_map_reverse
          // console.log('params_map:',this.params_map)
          // console.log('params_map_reverse:',this.params_map_reverse)
          resolve(resp)
        }).catch((err) => {
          if (err) {
            console.error(err)
          }
          reject(err)
        })
      })
    },
    reloadPage() {
      this.getPageList(this.page_option, this.search_option)
    },
    getPageList(pageOpt, searchOpt) {
      this.loading = true;
      getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
        this.loading = false;
        this.page_list = resp.data.records;
        this.page_option.page = pageOpt.page;
        this.page_option.total = resp.data.total;
        this.page_option.size = pageOpt.size;
      }).catch(() => {
        this.loading = false;
      })
    },
    handleSearchTap() {
      let searchOpt = Object.assign({}, this.search_option)
      let tmp_opt = this.search_option.time_range
      if (tmp_opt instanceof Array){
        let range_opt_start = formatTimestampDay(tmp_opt[0])
        let range_opt_end = formatTimestampDay(tmp_opt[1])
        let tmp_range_opt = range_opt_start+"||"+range_opt_end
        searchOpt.time_range = tmp_range_opt
      }
      console.log('searchOpt',searchOpt)
      this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
    },
    handleSearchClear(formName) {
      this.$refs[formName].resetFields();
      this.reloadPage()
    },
    handleSizeChange(pageSize) {
      this.getPageList({page: 1, size: pageSize}, this.search_option);
    },
    handleCurrentChange(pageIndex) {
      this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
    },
    onRowViewTap(row) {
      this.dialog_order_detail_visible = true
      this.dialog_order_detail_title = '订单号：' + row.id
      this.dialog_order_detail_id = row.id
    },
    elTimeStampFormat(row, column, val) {
      return formatTimestamp(val)
    },
    onTellDialogClose() {
      this.dialog_order_detail_visible = false;
      this.dialog_order_detail_id = '';
      this.reloadPage()
    },
    onExportTap(export_type) {
      let s_opt = Object.assign({}, this.search_option)
      let p_opt = Object.assign({}, this.page_option)
      let params = Object.assign({}, {export_type: export_type}, s_opt, p_opt)
      console.log('params:', params)
      exportExcel(params).then(res => {
        console.log('res:', res)
        const blob = new Blob([res.data]);//new Blob([res])中不加data就会返回下图中[objece objece]内容（少取一层）
        console.log('blob:', blob)
        if (res.data.type !== "application/force-download") {
          // console.log(res)
          console.log("文件类型不对:", blob.type)
          this.$message.warning("你无法下载订单")
          return
        }
        // const fileName = 'vstar订单.xlsx';//下载文件名称
        // let fileName = 'vstar订单.xlsx';//下载文件名称
        let fileName = this.getFileName(res) || 'vstar订单.xlsx'
        const elink = document.createElement('a');
        elink.download = fileName;
        // elink.download = this.getFileName(res);
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).catch(err => {
        this.$message.error("下载异常")
        console.log("catch:", err)
      })
    },
    getFileName(response) {
      let fileName = ''
      // 需要响应设置此header暴露给外部，才能获取到
      let contentDisposition = response.headers['content-disposition']
      if (contentDisposition) {
        // 正则获取filename的值
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

        let matches = filenameRegex.exec(contentDisposition)
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '')
        }
        // 通过 URLEncoder.encode(pFileName, StandardCharsets.UTF_8.name()) 加密编码的, 使用decodeURI(fileName) 解密
        fileName = decodeURI(fileName)
        // 通过 new String(pFileName.getBytes(), StandardCharsets.ISO_8859_1) 加密编码的, 使用decodeURI(escape(fileName)) 解密
        // fileName = decodeURI(escape(fileName))
      }
      return fileName
    },

    // 网络排序
    handleSortChange(e){
      this.search_option.sort_field = e.prop;
      let sort_dct = {ascending:"asc",descending:"desc",}
      this.search_option.sort = sort_dct[e.order]
      this.initPage()
    },
  }
}
</script>

<style scoped>

</style>
